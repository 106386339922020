import React, { Suspense } from 'react';
//import AppLoader from './AppLoader'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'reactstrap';
import loadable from '@react-loadable/revised';
import AppLoaderSpinner from 'components/others/AppLoaderSpinner';
const AppLoadable = loadable({
    loader: () => import('./AppLoader'),
    loading: () => React.createElement(Container, null,
        React.createElement(AppLoaderSpinner, null))
});
export default function App() {
    return React.createElement("div", { id: "app" },
        React.createElement(Suspense, { fallback: React.createElement(AppLoaderSpinner, null) },
            React.createElement(AppLoadable, null)));
}
